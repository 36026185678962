import {
  BackofficeDetailPromoManagement,
  BackofficePromoBannerUploadResponse,
  BackofficePromoQuery,
  BackofficePromoResponse,
  BackofficePromoUpdateRequest,
} from '@diamond/shared/types';
import { apiInstance } from '@diamond/shared/utils';
import { isAxiosError } from 'axios';

export const getPromoData = async (
  token: string,
  status: BackofficePromoQuery,
  page: number
) => {
  const url = `/backoffice/promo`;
  const { data } = await apiInstance(token).get<BackofficePromoResponse>(url, {
    params: {
      status: status,
      page,
      size: 25,
    },
  });

  return {
    data: data.data,
    nextPage: page < data.pagination.pages ? page + 1 : null,
  };
};

export class BackofficePromoError extends Error {
  status: number | undefined;
  subtitle: string;
  constructor(status: number | undefined, message: string, subtitle: string) {
    super(message);
    this.status = status;
    this.subtitle = subtitle;
  }
}

export async function getPromoManagementDetail(
  access_token: string,
  promo_id: string
) {
  try {
    const { data } = await apiInstance(
      access_token
    ).get<BackofficeDetailPromoManagement>(`/backoffice/promo/${promo_id}`);
    return data;
  } catch (error) {
    if (isAxiosError(error)) {
      throw new BackofficePromoError(
        error.response?.data.statusCode,
        error.response?.data.message,
        'Silahkan coba lagi'
      );
    }
    throw new BackofficePromoError(
      500,
      'Terjadi Kesalahan',
      'Silahkan coba lagi'
    );
  }
}

export async function updatePromoDetail(
  access_token: string,
  promo_id: string,
  payload: BackofficePromoUpdateRequest
) {
  return apiInstance(access_token).patch(
    `/backoffice/promo/${promo_id}`,
    payload
  );
}

export async function uploadPromoBanner<T>(access_token: string, payload: T) {
  const { data } = await apiInstance(access_token, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${access_token}`,
    },
  }).post<BackofficePromoBannerUploadResponse>(
    '/backoffice/promo/upload',
    payload
  );
  return data;
}
export const reorderPromo = async (
  token: string,
  id: string,
  index: number
) => {
  return apiInstance(token).patch(
    `/backoffice/promo/${id}/reorder`,
    JSON.stringify({ index })
  );
};
export async function updateStatusBranda(
  token: string,
  id: string,
  is_display_on_homepage: boolean
) {
  return apiInstance(token, {}).patch(
    `/backoffice/promo/${id}/show-on-homepage`,
    { is_display_on_homepage: is_display_on_homepage }
  );
}
