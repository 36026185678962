import { Box, HStack, Spacer } from '@chakra-ui/react';
import {
  BackofficeActivitiesPromos,
  BackofficeOrderPO,
} from '@diamond/shared/types';
import { Badge, DataTable, Divider, Text, TextField } from '@diamond/shared/ui';
import { adminCurrencyFormatter, catalogMasking } from '@diamond/shared/utils';
import { FieldValues, UseFormRegister } from 'react-hook-form';

import { columns } from './ActivitiesDetailPOColumns';

interface ActivitiesDetailPOTablesProps {
  register: UseFormRegister<FieldValues>;
  diamond?: BackofficeOrderPO['orders'][0];
  trading?: BackofficeOrderPO['orders'][0];
  equipment?: BackofficeOrderPO['orders'][0];
  food?: BackofficeOrderPO['orders'][0];
  distributionChannel: string;
}

export function ActivitiesDetailPOTables({
  register,
  diamond,
  trading,
  equipment,
  food,
  distributionChannel,
}: ActivitiesDetailPOTablesProps) {
  const mergeDataPromos = (
    items: BackofficeOrderPO['orders'][0]['items'] | undefined,
    promos: BackofficeActivitiesPromos | null | undefined
  ) => {
    if (promos === null) {
      return items;
    } else {
      const activitiesDiscountData = items?.map((discount) => {
        const matchDiscount = promos?.compiled_product_discounts.find(
          (discount2) => discount2.product_id === discount.product_id
        );

        return matchDiscount ? { ...discount, ...matchDiscount } : discount;
      });
      const activitiesDiscountDataSingle = activitiesDiscountData?.map(
        (discount) => {
          const matchDiscount = promos?.single_discount_promos.find(
            (discount2) => discount2.product_id === discount.product_id
          );

          if (matchDiscount) {
            const { id, ...rest } = matchDiscount; // Exclude the `id` field from matchDiscount
            return { ...discount, ...rest }; // Merge but keep the `id` from `discount`
          }

          return discount;
        }
      );
      const activitiesSingleFreegood = activitiesDiscountDataSingle?.flatMap(
        (discount) => {
          const matchFreegoodPromo = promos?.single_free_good_promos.find(
            (freeGoods) => freeGoods.ref_product_id === discount.product_id
          );

          return matchFreegoodPromo
            ? [discount, { ...discount, ...matchFreegoodPromo }]
            : [discount];
        }
      );
      const activitiesPromoData = activitiesSingleFreegood?.flatMap(
        (discount) => {
          const matchFreegoodPromo = promos?.bundled_free_good_promos.find(
            (freeGoods) => freeGoods.ref_product_id === discount.product_id
          );

          return matchFreegoodPromo
            ? [discount, { ...discount, ...matchFreegoodPromo }]
            : [discount];
        }
      );
      return activitiesPromoData;
    }
  };
  return (
    <>
      {diamond && (
        <Box py="2">
          <Box>
            <HStack my="3" spacing="4" direction="row">
              <Box>
                <Text fontSize="xl" fontWeight="bold">
                  Nomor Sub PO SOL : {diamond.sub_po_number} -{' '}
                  <Badge variant="blue" fontSize="md">
                    {catalogMasking(diamond.division, distributionChannel)}
                  </Badge>
                </Text>
              </Box>
              <Spacer />
              <Box>
                <TextField
                  width="25rem"
                  name="total"
                  placeholder={'Total Nilai Order'}
                  register={register}
                  value={`Total Order Per Divisi: ${adminCurrencyFormatter(
                    diamond.subtotal
                  )}`}
                  isDisabled
                  _disabled={{
                    color: 'black',
                  }}
                />
              </Box>
            </HStack>
          </Box>
          <Box pb="5">
            <Divider />
          </Box>
          <DataTable
            columns={columns}
            data={mergeDataPromos(diamond.items, diamond.promos) ?? []}
          />
        </Box>
      )}
      {trading && (
        <Box py="2">
          <Box>
            <HStack my="3" spacing="4" direction="row">
              <Box>
                <Text fontSize="xl" fontWeight="bold">
                  Nomor Sub PO SOL : {trading.sub_po_number} -{' '}
                  <Badge variant="blue" fontSize="md">
                    {catalogMasking(trading.division, distributionChannel)}
                  </Badge>
                </Text>
              </Box>
              <Spacer />
              <Box>
                <TextField
                  width="25rem"
                  name="total"
                  placeholder={'Total Nilai Order'}
                  register={register}
                  value={`Total Order Per Divisi: ${adminCurrencyFormatter(
                    trading.subtotal
                  )}`}
                  isDisabled
                  _disabled={{
                    color: 'black',
                  }}
                />
              </Box>
            </HStack>
          </Box>
          <Box pb="5">
            <Divider />
          </Box>
          <DataTable
            columns={columns}
            data={mergeDataPromos(trading.items, trading.promos) ?? []}
          />
        </Box>
      )}
      {equipment && (
        <Box py="2">
          <Box>
            <HStack my="3" spacing="4" direction="row">
              <Box>
                <Text fontSize="xl" fontWeight="bold">
                  Nomor Sub PO SOL : {equipment.sub_po_number} -{' '}
                  <Badge variant="blue" fontSize="md">
                    {catalogMasking(equipment.division, distributionChannel)}
                  </Badge>
                </Text>
              </Box>
              <Spacer />
              <Box>
                <TextField
                  width="25rem"
                  name="total"
                  placeholder={'Total Nilai Order'}
                  register={register}
                  value={`Total Order Per Divisi: ${adminCurrencyFormatter(
                    equipment.subtotal
                  )}`}
                  isDisabled
                  _disabled={{
                    color: 'black',
                  }}
                />
              </Box>
            </HStack>
          </Box>
          <Box pb="5">
            <Divider />
          </Box>
          <DataTable
            columns={columns}
            data={mergeDataPromos(equipment.items, equipment.promos) ?? []}
          />
        </Box>
      )}
      {food && (
        <Box py="2">
          <Box>
            <HStack my="3" spacing="4" direction="row">
              <Box>
                <Text fontSize="xl" fontWeight="bold">
                  Nomor Sub PO SOL : {food.sub_po_number} -{' '}
                  <Badge variant="blue" fontSize="md">
                    {catalogMasking(food.division, distributionChannel)}
                  </Badge>
                </Text>
              </Box>
              <Spacer />
              <Box>
                <TextField
                  width="25rem"
                  name="total"
                  placeholder={'Total Nilai Order'}
                  register={register}
                  value={`Total Order Per Divisi: ${adminCurrencyFormatter(
                    food.subtotal
                  )}`}
                  isDisabled
                  _disabled={{
                    color: 'black',
                  }}
                />
              </Box>
            </HStack>
          </Box>
          <Box pb="5">
            <Divider />
          </Box>
          <DataTable
            columns={columns}
            data={mergeDataPromos(food.items, food.promos) ?? []}
          />
        </Box>
      )}
    </>
  );
}
