export const statusData = [
  {
    label: 'Menunggu Pembayaran',
    value: 'waiting_for_payment',
  },
  {
    label: 'Menunggu Konfirmasi',
    value: 'submitted',
  },
  {
    label: 'Diproses',
    value: 'processed',
  },
  {
    label: 'Dikirim',
    value: 'out_for_delivery',
  },
  {
    label: 'Diterima',
    value: 'received',
  },
  {
    label: 'Diterima Sebagian',
    value: 'partially_received',
  },
  {
    label: 'Diproses Sebagian',
    value: 'partially_processed',
  },
  {
    label: 'Dikirim Sebagian',
    value: 'partially_out_for_delivery',
  },
  {
    label: 'Dibatalkan',
    value: 'cancelled',
  },
];

export const statusFormatter = (value?: string) => {
  if (value) {
    const filteredStatus = statusData
      .filter((status) => status.value === value)
      .pop();
    return filteredStatus?.label;
  } else {
    return '';
  }
};

export function formatOrderStatus(status: string) {
  switch (status) {
    case 'submitted':
      return 'Menunggu Konfirmasi';
    case 'waiting_for_payment':
      return 'Menunggu pembayaran';
    case 'processed':
      return 'Diproses';
    case 'out_for_delivery':
      return 'Out For Delivery';
    case 'received':
      return 'Diterima';
    case 'partially_received':
      return 'Diterima Sebagian';
    case 'partially_processed':
      return 'Diproses Sebagian';
    case 'partially_out_for_delivery':
      return 'Dikirim Sebagian';
    case 'cancelled':
      return 'Dibatalkan';
    default:
      return 'Draft';
  }
}

type StatusMap = {
  [key in string]: {
    state: string;
    stateOptions: { color: string; bg: string };
  };
};

export const statusMap: StatusMap = {
  submitted: {
    state: 'Menunggu Konfirmasi',
    stateOptions: { color: 'blueDark', bg: 'rgba(0, 104, 163, 0.1)' },
  },
  waiting_for_payment: {
    state: 'Menunggu pembayaran',
    stateOptions: { color: 'orange', bg: 'rgba(226, 136, 0, 0.1)' },
  },
  processed: {
    state: 'Diproses',
    stateOptions: { color: 'purple', bg: 'rgba(82, 0, 163, 0.1)' },
  },
  out_for_delivery: {
    state: 'Dikirim',
    stateOptions: { color: 'purple', bg: 'rgba(82, 0, 163, 0.1)' },
  },
  received: {
    state: 'Diterima',
    stateOptions: { color: 'blue', bg: 'rgba(0, 104, 163, 0.1)' },
  },
  partially_received: {
    state: 'Diterima Sebagian',
    stateOptions: { color: 'darkGreen', bg: 'rgba(0, 85, 39, 0.1)' },
  },
  partially_processed: {
    state: 'Diproses Sebagian',
    stateOptions: { color: 'purple', bg: 'rgba(82, 0, 163, 0.1)' },
  },
  partially_out_for_delivery: {
    state: 'Dikirim Sebagian',
    stateOptions: { color: 'purple', bg: 'rgba(82, 0, 163, 0.1)' },
  },
  cancelled: {
    state: 'Dibatalkan: Stok Habis',
    stateOptions: { color: 'red', bg: 'rgba(255, 0, 0, 0.1)' },
  },
  draft: {
    state: 'Draft',
    stateOptions: {
      color: 'blue',
      bg: 'rgba(0, 0, 163, 0.1)',
    },
  },
};

const masking = [
  {
    label: 'Diamond',
    value: 'diamond',
  },
  {
    label: 'Trading',
    value: 'trading',
  },
  {
    label: 'FineFood',
    value: 'food',
  },
  {
    label: 'FineFood',
    value: 'fnb',
  },
  {
    label: 'Equipment',
    value: 'equipment',
  },
];

const outsideDAMasking = [
  {
    label: 'Food',
    value: 'diamond',
  },
  {
    label: 'Food',
    value: 'trading',
  },
  {
    label: 'Food',
    value: 'food',
  },
  {
    label: 'Food',
    value: 'fnb',
  },
  {
    label: 'Equipment',
    value: 'equipment',
  },
];

export function catalogMasking(division: string, distribution_channel: string) {
  const maskingArray =
    distribution_channel === 'DA' ? masking : outsideDAMasking;
  const result = maskingArray.find((val) => val.value === division);
  return result ? result.label : ''; // Return label if a match is found, or an empty string if not.
}

export function divisionMasking(division: string) {
  const result = masking.find((val) => val.value === division);
  return result;
}

const storageFormats = {
  dry: 'Dry',
  frozen: 'Frozen',
  dry_ac: 'Dry AC',
  chilled: 'Chilled',
};

export function storageFormatFormatter(value: keyof typeof storageFormats) {
  if (value) {
    return storageFormats[value];
  }
  return '';
}
