import { Box, Center, Skeleton, Spinner } from '@chakra-ui/react';
import { BackofficePromoQuery } from '@diamond/shared/types';
import {
  DropResult,
  LoadingOverlay,
  Sortable,
  SortableItem,
} from '@diamond/shared/ui';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import {
  BACKOFFICE_PROMO_QUERY_KEY,
  reorderPromo,
  useBackofficePromo,
} from '@diamond/sol-admin-context';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import HeadingSortItem from './HeadingSortItem';
import PromoItem from './PromoItem';

export interface PromoItemListProps {
  status: BackofficePromoQuery;
}

type ReorderPromoArgs = {
  id: string;
  destinationIndex: number;
};

export function PromoItemList(props: PromoItemListProps) {
  const session = useAuthStore();
  const { ref, inView } = useInView();
  const queryClient = useQueryClient();

  const {
    data: promo,
    isPending,
    fetchNextPage,
    isFetchingNextPage,
    dataUpdatedAt,
  } = useBackofficePromo(props.status);

  const reorderMutation = useMutation({
    mutationFn: ({ id, destinationIndex }: ReorderPromoArgs) =>
      reorderPromo(session.access_token, id, destinationIndex),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [BACKOFFICE_PROMO_QUERY_KEY, props.status],
      });
    },
  });

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage]);

  if (isPending) {
    return (
      <Box py={4}>
        <Skeleton height="540px" borderRadius="md" />
      </Box>
    );
  }

  const promotionsActive: SortableItem[] =
    promo !== undefined
      ? promo.pages.flatMap((page) =>
          page.data.map((promo, index) => {
            return {
              id: promo.id,
              originalIndex: promo.index,
              children: <PromoItem item={promo} nomor={index + 1} />,
            };
          })
        )
      : [];

  const handleIndexChange = (result: DropResult, items: SortableItem[]) => {
    if (!result.destination) return;
    const destinationIdx = items[result.destination.index].originalIndex; // Target original index rather than draggable index
    if (typeof destinationIdx === 'undefined') return;
    reorderMutation.mutate({
      id: result.draggableId,
      destinationIndex: destinationIdx,
    });
  };

  return (
    <>
      <LoadingOverlay
        isLoading={reorderMutation.isPending}
        text="Mengubah urutan promo..."
      />
      <Box my={8} width="full">
        <HeadingSortItem />
        <Sortable
          key={dataUpdatedAt}
          data={promotionsActive}
          handleChange={handleIndexChange}
          shouldRefetchData={false}
        />
        <Box ref={ref}></Box>
        {isFetchingNextPage && (
          <Center mt={4}>
            <Spinner />
          </Center>
        )}
      </Box>
    </>
  );
}
