import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Link as ChakraLink,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Spacer,
  Stack,
  useToast,
} from '@chakra-ui/react';
import { usePaginationAdmin } from '@diamond/shared/context';
import {
  PatchDataWrapper,
  PostDataWrapper,
  QueryWrapper,
} from '@diamond/shared/data-access';
import { Account } from '@diamond/shared/types';
import {
  Breadcrumbs,
  DataTable,
  Divider,
  Dropdown,
  Icon,
  PageLoader,
  Pagination,
  Searchbox,
  showToast,
  Text,
} from '@diamond/shared/ui';
import { adminDateFormatter, numberSeparator } from '@diamond/shared/utils';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import {
  AccountColumnType,
  AdminLayout,
  IndeterminateCheckbox,
} from '@diamond/sol-admin/common';
import { Edit } from '@mui/icons-material';
import { createColumnHelper } from '@tanstack/react-table';
import cleanDeep from 'clean-deep';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';

const API_URL = 'backoffice/account/buyer';
const ACTION_URL = '/backoffice/user/action';
const RESET_PASS_URL = '/backoffice/user/reset-password';

export function AccountPage() {
  const session = useAuthStore();
  const toast = useToast();
  const [selectedData, setSelectedData] = useState<any[]>([]);
  const columnHelper = createColumnHelper<AccountColumnType>();

  const [searchParams, setSearchParams] = useSearchParams();

  const setParams = useCallback(
    (nextParams: Record<string, any>) => {
      const prevParams = {
        page: '1',
        size: '25',
        query: '',
        ...Object.fromEntries(searchParams.entries()),
      };

      setSearchParams(cleanDeep({ ...prevParams, ...nextParams }));
    },
    [searchParams]
  );

  const params = useMemo(() => {
    const rawParams = {
      page: '1',
      size: '25',
      query: '',
      ...Object.fromEntries(searchParams.entries()),
    };
    return {
      page: Number(rawParams.page),
      size: Number(rawParams.size),
      query: rawParams.query,
    };
  }, [searchParams]);

  const { register, watch } = useForm({
    defaultValues: {
      search_query: params.query,
    } as FieldValues,
  });

  const timeout = useRef<any>();

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'search_query') {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
          setParams({ page: '1', query: value[name] });
        }, 500);
      }
    });
    return () => {
      clearTimeout(timeout.current);
      subscription.unsubscribe();
    };
  }, [setParams, watch]);

  const links = [
    {
      title: 'Beranda',
      link: '/',
    },
    {
      title: 'Account & Contact',
      link: '/account',
    },
  ];

  const query = QueryWrapper(API_URL, session.access_token);
  const { patchData } = PatchDataWrapper(
    ACTION_URL,
    session.access_token,
    'admin'
  );
  const { postData } = PostDataWrapper(
    RESET_PASS_URL,
    session.access_token,
    false,
    'admin'
  );

  const {
    data: allAccount,
    pageCount,
    total,
    isLoading,
    refetch: refetchAccount,
    isFetching,
  } = query.useQueryData<Account>(
    'Account',
    {
      size: params.size,
      page: params.page,
      search_query: params.query.trimStart().trimEnd(),
    },
    true
  );

  const { nextPage, previousPage, lastPage, firstPage } = usePaginationAdmin(
    params.page,
    (value) => setParams({ page: `${value}` }),
    refetchAccount,
    pageCount
  );

  const handleSelectAll = (values: any, isChecked: boolean) => {
    const list = isChecked ? [] : [...values];
    setSelectedData(list);
  };

  const handleSelectOneRow = (value: any, isCheck: boolean) => {
    const list = isCheck
      ? selectedData.filter((item) => item !== value)
      : [...selectedData, value];
    setSelectedData(list);
  };

  const tableColumn: any = [
    {
      id: 'select',
      header: ({ table }: any) => (
        <IndeterminateCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
          onClick={() => {
            handleSelectAll(
              table.getRowModel().rows,
              table.getIsAllRowsSelected()
            );
          }}
        />
      ),
      cell: ({ row }: any) => (
        <div className="px-1">
          <IndeterminateCheckbox
            checked={row.getIsSelected()}
            indeterminate={row.getIsSomeSelected()}
            onChange={row.getToggleSelectedHandler()}
            onClick={() => {
              handleSelectOneRow(row, row.getIsSelected());
            }}
          />
        </div>
      ),
    },
    columnHelper.accessor('first_name', {
      cell: (info) => info.getValue(),
      header: 'Nama Depan',
    }),
    columnHelper.accessor('last_name', {
      cell: (info) => info.getValue(),
      header: 'Nama Belakang',
    }),
    columnHelper.accessor('email', {
      cell: ({ row }: any) => (
        <ChakraLink
          as={Link}
          id={`email-${row.original.email}`}
          textDecoration="underline"
          to={`/account/${row.original.id}/${row.original.ship_to.id}`}
        >
          {row.original.email}
        </ChakraLink>
      ),
      header: 'Email',
    }),
    columnHelper.accessor('bill_to.code', {
      cell: (info) => (info.getValue() ? info.getValue() : ''),
      header: 'ID Kontak',
    }),
    {
      id: 'bill_to.company_name',
      header: 'Nama Perusahaan',
      cell: ({ row }: any) => (
        <ChakraLink
          as={Link}
          textDecoration="underline"
          to={`/account/${row.original.id}/${row.original.ship_to.id}`}
        >
          {row.original?.bill_to?.company_name}
        </ChakraLink>
      ),
    },
    columnHelper.accessor('ship_to.code', {
      cell: (info) => info.getValue(),
      header: 'Shipto ID',
    }),
    columnHelper.accessor('ship_to.distribution_channel', {
      cell: (info) => info.getValue(),
      header: 'Distribution Channel',
    }),
    columnHelper.accessor('bill_to.created_at', {
      cell: (info) => {
        return adminDateFormatter(info.getValue());
      },
      header: 'Waktu Kontak Dibuat',
    }),
    columnHelper.accessor('ship_to.created_at', {
      cell: (info) => {
        return adminDateFormatter(info.getValue());
      },
      header: 'Waktu Akun Dibuat',
    }),
  ];

  const handleCanLogin = () => {
    for (let i = 0; i < selectedData.length; i++) {
      const element = selectedData[i].original;
      patchData({ status: 'active' }, element.id);
    }
  };
  const handleCannotLogin = () => {
    for (let i = 0; i < selectedData.length; i++) {
      const element = selectedData[i].original;
      patchData({ status: 'inactive' }, element.id);
    }
  };
  const handleResetPassword = () => {
    for (let i = 0; i < selectedData.length; i++) {
      const element = selectedData[i].original;
      const payload = {
        email: element.email,
      };
      postData(payload)
        .then(() => {
          showToast(
            toast,
            'success',
            'Permintaan reset password berhasil dikirim!'
          );
        })
        .catch((error) => {
          showToast(toast, 'error', error.message);
        });
    }
  };

  const handleView = (value: string) => {
    setParams({ page: '1', size: value });
  };

  if (isLoading) {
    return (
      <AdminLayout>
        <PageLoader />
      </AdminLayout>
    );
  }

  return (
    <AdminLayout maxWidth="full">
      <Box paddingBottom={4}>
        <Breadcrumbs links={links} useRouterDom />
      </Box>
      <Stack>
        <Flex alignItems="center">
          {selectedData.length > 0 && (
            <Menu>
              <MenuButton as={Button} px={0} colorScheme="pink">
                <Icon as={Edit} />
              </MenuButton>
              <Portal>
                <MenuList zIndex={10}>
                  <MenuItem onClick={handleCanLogin}>Bisa Login</MenuItem>
                  <MenuItem onClick={handleCannotLogin}>
                    Tidak Bisa Login
                  </MenuItem>
                  <MenuItem onClick={handleResetPassword}>
                    Reset Password
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
          )}
          <Text textColor="blue" ml={4} verticalAlign="baseline">
            {`${numberSeparator(total)} Hasil`}
          </Text>
          <Spacer />
          <Searchbox register={register} placeholder="Cari..." />
        </Flex>
        <Stack py={4}>
          <Divider borderWidth="3px" borderColor="gray.33" />
        </Stack>
        <DataTable
          data={allAccount}
          columns={tableColumn}
          isLoading={isFetching}
          // Temporary disable
          // selectedRowAction={(rowItem) =>
          //   navigate(`/account/${rowItem.id}/${rowItem.ship_to.id}`)
          // }
        />
        <Center pt={4}>
          <Pagination
            currentPage={params.page}
            totalPage={pageCount}
            nextPage={nextPage}
            firstPage={firstPage}
            lastPage={lastPage}
            previousPage={previousPage}
          />
        </Center>
      </Stack>
    </AdminLayout>
  );
}
export default AccountPage;
