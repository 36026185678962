import { BackofficePromoQuery } from '@diamond/shared/types';
import { useAuthStore } from '@diamond/sol-admin/authentication';
import { useInfiniteQuery } from '@tanstack/react-query';

import { getPromoData } from '../queries';

export const BACKOFFICE_PROMO_QUERY_KEY = 'BackofficePromo';

export function useBackofficePromo(status: BackofficePromoQuery) {
  const { access_token } = useAuthStore();
  return useInfiniteQuery({
    queryKey: [BACKOFFICE_PROMO_QUERY_KEY, status],
    queryFn: ({ pageParam }) => getPromoData(access_token, status, pageParam),
    initialPageParam: 1,
    getNextPageParam: ({ nextPage }) => nextPage,
  });
}
