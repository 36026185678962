import {
  Box,
  Flex,
  Image as ChakraImage,
  Image,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { STOCK_UI } from '@diamond/shared/decimal-util-web';
import { LabelPromo, PromoPrice, Text } from '@diamond/shared/ui';
import { adminCurrencyFormatter } from '@diamond/shared/utils';
import { createColumnHelper } from '@tanstack/react-table';
import { dinero, toDecimal } from 'dinero.js';

export type ColumnsType = {
  item_code: number;
  images: string[];
  quantity?: number;
  subtotal: number;
  title: string;
  price?: number;
  is_new_product: boolean;
};

const columnHelper = createColumnHelper<any>();

export const columns = [
  columnHelper.accessor('item_code', {
    cell: (info) => {
      const isNewProduct = info.row.original.is_new_product;
      return (
        <VStack alignItems="flex-start">
          {isNewProduct ? (
            <span
              style={{
                display: 'inline-block',
                backgroundColor: 'red',
                padding: '0.25rem 0.5rem',
                fontSize: '75%',
                fontWeight: 600,
                lineHeight: 1,
                textAlign: 'center',
                whiteSpace: 'nowrap',
                verticalAlign: 'baseline',
                borderRadius: '0.25rem',
                color: 'white',
              }}
            >
              New
            </span>
          ) : null}
          <span>{info.getValue()}</span>
        </VStack>
      );
    },
    header: 'Kode Item',
  }),
  columnHelper.accessor('images', {
    cell: (info) => {
      const imageData = info.row.original.images;
      const image = imageData
        ? imageData[imageData.length - 1]
        : 'assets/images/product_placeholder.png';
      return (
        <Image
          as={ChakraImage}
          src={image}
          alt={''}
          objectFit="cover"
          borderRadius="md"
          border="1px"
          borderColor="gray.33"
          w="10"
          h="10"
        />
      );
    },
    header: 'Gambar',
  }),
  columnHelper.accessor('title', {
    cell: (info) => {
      return (
        <Box>
          <Text mb="1">{info.getValue()}</Text>
          {info.row.original.is_discount === true ? (
            <Flex>
              <Stack mr="2"></Stack>
              <LabelPromo type="discount" />
            </Flex>
          ) : info.row.original.is_discount === false ? (
            <Flex>
              <Stack mr="2"></Stack>
              <LabelPromo type="cashback" />
            </Flex>
          ) : info.row.original.quantity_free_goods ? (
            <LabelPromo type="free-item" isFreeGoods />
          ) : null}
        </Box>
      );
    },
    header: 'Nama Produk',
  }),
  columnHelper.accessor('quantity', {
    cell: (info) => {
      const jumlahCheckout = Number(
        toDecimal(
          dinero({
            amount: Number(info.getValue()),
            currency: STOCK_UI,
          })
        )
      );
      return (
        <VStack alignItems="flex-start">
          {info.row.original.quantity_free_goods ? (
            <Text>
              {Number(
                toDecimal(
                  dinero({
                    amount: Number(info.row.original.quantity_free_goods),
                    currency: STOCK_UI,
                  })
                )
              )}
            </Text>
          ) : (
            <Text>{jumlahCheckout}</Text>
          )}
        </VStack>
      );
    },
    header: 'Jumlah Checkout',
  }),
  columnHelper.accessor('price', {
    cell: (info) => {
      const quantityItem = info.row.original.quantity_free_goods;

      return (
        <Stack>
          <Text>
            {info.row.original.is_discount
              ? adminCurrencyFormatter(
                  info.row.original.price_after_discount ?? 0
                )
              : quantityItem
              ? undefined
              : adminCurrencyFormatter(info.getValue())}
          </Text>
          {info.row.original.is_discount ? (
            <PromoPrice
              formattedPrice={adminCurrencyFormatter(info.getValue())}
              discountAmount={info.row.original.deduction_amount}
              isActive
            />
          ) : quantityItem ? (
            <PromoPrice
              formattedPrice={adminCurrencyFormatter(info.row.original.price)}
              isFreeGoods
            />
          ) : undefined}
        </Stack>
      );
    },
    header: 'Harga',
  }),
  columnHelper.accessor('subtotal', {
    cell: (info) =>
      info.row.original.quantity_free_goods
        ? adminCurrencyFormatter(0)
        : adminCurrencyFormatter(info.getValue()),
    header: 'Total Harga',
  }),
];
